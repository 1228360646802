import * as React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const InnerSection = ({ variant, className, children }) => {
    return (
        <div
            className={`c-inner-section${
                variant
                    ? ` c-inner-section--${variant}`
                    : ' c-inner-section--light'
            }${className && ` ${className}`}`}
        >
            {children}
        </div>
    )
}

InnerSection.propTypes = {
    /**
     * Specify a different style variant
     */
    variant: PropTypes.oneOf(['light', 'dark', 'alt', 'gradient']),
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired
}

InnerSection.defaultProps = {
    variant: 'light',
    className: '',
    children: '<p>Section content goes here</p>'
}

export default InnerSection
